/* Container for full-width split section */
.full-width-section {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 50vh; /* Minimum height to give breathing room */
}

/* Left side (Image section) */
.left-side {
  background-color: rgba(242, 229, 194, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}
.left-side-1 {
  background-color: rgba(242,229,161,255);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.poke-image {
  max-width: 90%; /* Adds padding within the container */
  border-radius: 10px;
  object-fit: cover; /* Ensures the image fits nicely */
}

/* Right side (Text section) */
.right-side {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 50%;
  text-align: center;
}

.poke-title {
  font-size: 2.5em;
  font-weight: bold;
  margin: 10px 0;
}

.poke-subtitle {
  font-style: italic;
  font-size: 1.3em;
  margin-bottom: 15px;
}

.poke-description {
  font-size: 1em;
  margin-bottom: 20px;
  line-height: 1.6;
  max-width: 80%; /* Limits text width for better readability */
}

.menu-button {
  font-size: 1em;
  padding: 10px 20px;
  border: 1px solid #333;
  background-color: transparent;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.menu-button:hover {
  background-color: #333;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .poke-title {
    font-size: 2em;
  }
  
  .poke-subtitle {
    font-size: 1.1em;
  }
}

@media (max-width: 768px) {
  .full-width-section {
    flex-direction: column;
    min-height: auto; /* Adjust height for stacking layout */
  }

  .left-side, .right-side {
    width: 100%; /* Full width on smaller screens */
  }
  .left-side-1{
    width: 100%; /* Full width on smaller screens */
  }

  .right-side {
    padding: 20px;
  }

  .poke-title {
    font-size: 1.8em;
  }

  .poke-subtitle {
    font-size: 1em;
  }

  .poke-description {
    font-size: 0.9em;
    max-width: 90%; /* Widen text area for smaller screens */
  }

  .menu-button {
    padding: 8px 16px;
  }
}
