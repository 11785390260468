.header {
  background-color: #a6e01e; /* Green background color */
  border-bottom: 5px solid rgb(175, 173, 173);
}


.hidden { 
  display: none;
}
.header-container{ 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-nav {
  flex-grow: 1;
  justify-content: center; /* Center the nav items */
}

.nav-item {
  color: #333;
  font-weight: bold;
  margin: 0 15px;
}

.nav-item:hover {
  color: #000; /* Slight color change on hover */
}

.logo {
  width: 55px; /* Adjust logo size */
  height: 55px;
}
