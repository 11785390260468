.about-us .logo {
    width: 150px;
    margin-bottom: 10px;
  }
  
  .about-us.container {
    max-width: 900px;
  }
  
  .slide-in-left {
    opacity: 0;
    transform: translateX(-50px);
    transition: all 0.5s ease;
  }
  
  .slide-in-right {
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.5s ease;
  }
  
  .slide-in-left.show, .slide-in-right.show {
    opacity: 1;
    transform: translateX(0);
  }
  
  /* Responsive adjustments for small screens */
  @media (max-width: 768px) {
    .about-us .logo {
      width: 100px;
    }
    
    .container {
      padding: 0 15px;
    }
    
    .about-us h1 {
      font-size: 1.5rem;
    }
  
    /* Stack vertically on small screens */
    .row {
      flex-direction: column;
      text-align: center;
    }
  
    .col-lg-6 {
      order: unset !important;
    }
  }
  