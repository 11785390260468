.hero-section {
  background-image: url("../../elements//hero1.jpg");
  background-size: cover;
  background-position: center;
  height: 80vh; /* Adjusts height for larger screens */
  max-height: 800px; /* Limits max height */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
}

.overlay {
  text-align: center;
  color: white;
}

.hero-logo {
  width: 600px; /* Default width for larger screens */
  max-width: 90vw; /* Ensures it stays within view on smaller screens */
  margin-bottom: 20px;
  transition: width 0.3s ease; /* Smoothly adjust size on screen resize */
}

.hero-text {
  font-size: 1.5em;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 10px 20px;
  margin-bottom: 20px;
}

.menu-button-1 {
  font-size: 1em;
  padding: 10px 30px;
  border: 2px solid white;
  background-color: #a6e01e;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
  bottom: 0px;
}

.menu-button:hover {
  background-color: white;
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .hero-logo {
    width: 500px;
  }
  .hero-section {
    height: 70vh; /* Shrink the section height on medium screens */
  }
}

@media (max-width: 992px) {
  .hero-logo {
    width: 400px;
  }
  .hero-section {
    height: 60vh; /* Shrink further on smaller screens */
  }
}

@media (max-width: 768px) {
  .hero-logo {
    width: 300px;
  }
  .hero-section {
    height: 50vh; /* Further reduction on mobile screens */
  }
}

@media (max-width: 576px) {
  .hero-logo {
    width: 200px;
  }
  .hero-text {
    font-size: 1.2em; /* Adjust text size on smaller screens */
  }
  .menu-button {
    font-size: 0.9em; /* Slightly reduce button text size */
    padding: 8px 16px;
  }
  .hero-section {
    height: 40vh; /* Minimum height on the smallest screens */
  }
}
