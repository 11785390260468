.hero-section-2 {
    background-image: url('https://static.wixstatic.com/media/563bec_1367b66094d14881a7ca7ed06d3cd464~mv2.jpg/v1/fill/w_1385,h_780,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/563bec_1367b66094d14881a7ca7ed06d3cd464~mv2.jpg');
    background-size: cover;
    background-position: center;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .overlay {
    text-align: center;
    color: white;
  }
  
  .hero-subtitle {
    font-size: 1.2em;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  
  .hero-title {
    font-size: 4em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .order-button {
    font-size: 1em;
    padding: 10px 20px;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    letter-spacing: 1px;
  }
  
  .order-button:hover {
    background-color: white;
    color: #333;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .hero-section-2 {
      height: 40vh; /* Shrink the section height on smaller screens */
      margin-top: 0;
    }
  
    .hero-subtitle {
      font-size: 1em;
    }
  
    .hero-title {
      font-size: 2.5em;
    }
  
    .order-button {
      font-size: 0.9em;
      padding: 8px 16px;
    }
  }
  