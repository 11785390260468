/* Footer Container */
.footer {
  background-color: #a6e01e; /* Green background color */
  padding: 20px 0;
  text-align: center;
  font-family: Arial, sans-serif;
}

.footer-strip {
  background-color: #d0e86f; /* Lighter green for contrast */
  text-align: center;
  padding: 5px 0; /* Increased padding to give more space for the button */
  position: relative; /* Set relative positioning for absolute positioning of button */
}

.top-button {
  background-color: white;
  border: 2px solid #333;
  color: #333;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
  position: absolute;
  top: -15px; /* Moves the button above the strip */
  left: 50%;
  transform: translateX(-50%); /* Centers the button horizontally */
}

/* Optional hover effect */
.top-button:hover {
  background-color: #333;
  color: white;
}

.top-button:hover {
  background-color: #333;
  color: white;
}

/* Footer Content Layout */
.footer-content {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  flex-wrap: wrap;
}

.location {
  max-width: 250px;
  margin: 10px;
}

.location h3 {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.location p {
  font-size: 0.9em;
  color: #333;
  line-height: 1.5;
}

/* Footer Bottom */
.footer-bottom {
  font-size: 0.8em;
  color: #333;
  padding-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .location {
    max-width: 100%;
    text-align: center;
  }
}
