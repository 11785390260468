/* Wrapper for the gallery and buttons */
.gallery-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 80px;
}

/* Main container for horizontal scroll */
.gallery-container {
  display: flex;
  overflow-x: auto;
  gap: 5px;
  box-sizing: border-box;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  width: 100%; /* Ensures it spans the full viewport width */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.gallery-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

/* Left and right scroll buttons */
.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.scroll-button.left {
  left: 10px;
}

.scroll-button.right {
  right: 10px;
}

/* Main image container (left or right) */
.main-image-container, .middle-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-shrink: 0; /* Prevents containers from shrinking */
  width: calc(100vw / 3.5); /* Adjust based on how many containers fit in the view */
  scroll-snap-align: start;
}

/* Top section with two side-by-side images */
.main-image-container-top {
  display: flex;
  gap: 5px;
  height: 40%;
  margin-right: 5px;
}

.img-1 {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

/* Bottom section with a single image */
.main-image-container-bottom {
  height: 60%;
}

.img-2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Middle container with two vertically stacked images */
.img-middle {
  width: 100%;
  height: 50%;
  object-fit: cover;
}

/* Responsive adjustments */
/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .gallery-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between each image container */
    width: 100%;
    overflow: hidden; /* Remove horizontal scroll on mobile */
  }

  .main-image-container, .middle-container {
    flex-direction: row;
    width: 100%; /* Full width for each row on mobile */
    flex-wrap: wrap; /* Allows alternating left-right arrangement */
  }

  .main-image-container-top{
    width: 50%; /* Each image takes up half the width in row */
  }
   .main-image-container-bottom {
    width: 100%;
  }

  .img-1, .img-2, .img-middle {
    width: 100%;
    height: auto; /* Allow images to maintain aspect ratio */
  }

  .scroll-button {
    display: none; /* Hide arrows on mobile */
  }
}
